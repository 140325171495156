import React from "react"
import styled from "styled-components"
import { Seo } from "components/common"
import { graphql } from "gatsby"

import { useMeta, useContentfulSection } from "utils"

const PrivacyPolicy = ({ data: { pp } }) => {
  const { privacy_policy_pdf } = useContentfulSection(pp.sections)

  const meta = useMeta(pp)
  return (
    <>
      <Seo {...meta} />

      <Wrap>
        <iframe
          title="privacy-policy"
          src={privacy_policy_pdf.image.file.url}
          height="100%"
          width="100%"
          frameBorder="0"
        />
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  height: 100vh;
  overflow: hidden;
`

export const query = graphql`
  {
    pp: contentfulPage(pageId: { eq: "privacy_policy" }) {
      ...Page
    }
  }
`

export default PrivacyPolicy
